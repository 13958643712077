export default `
uniform sampler2D tMask;

void mainImage(const in vec4 inputColor, const in vec2 uv, out vec4 outputColor) {
    float alpha = texture2D(tMask,uv).r;
    vec4 maskColor = vec4(alpha, alpha, alpha, alpha);
    outputColor = inputColor * maskColor;
    // outputColor = vec4(texture2D(inputColor,uv).r, texture2D(inputColor,uv).g, texture2D(inputColor,uv).r, alpha);
    // outputColor = vec4(inputColor.r, inputColor.g, inputColor.b, alpha);
    // outputColor = inputColor * maskColor;

}
`;