import { Uniform, Texture } from "three";
import { BlendFunction, Effect } from "postprocessing";

import fragmentShader from "./alphaMaskEffect.frag";

export class AlphaMaskEffect extends Effect {

	constructor(texture) {
		super("AlphaMaskEffect", fragmentShader, {
			blendFunction: BlendFunction.NORMAL,
			uniforms: new Map([
				["tMask", new Uniform( texture? texture : new Texture() )]
			])
		});
	}


	get tMask() {

		return this.uniforms.get("tMask").value;

	}

	set tMask(value) {

		this.uniforms.get("tMask").value = value;

	}
}