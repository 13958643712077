import React, { useEffect, useRef, useState } from 'react';
import './body-tracker/aframe.js';
// import 'aframe-effects';
// import { ImageSegmenter, FilesetResolver, ImageSegmenterResult } from '@mediapipe/tasks-vision';
// import * as wasm from '@mediapipe/tasks-vision/wasm/vision_wasm_internal';
//import 'aframe';
//import 'mind-ar/dist/mindar-image-aframe.prod.js';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // const animationList = ['open wing type 1', 'expand wing type 1', 'dispand wing type 1', 'close wing type 1']
  const animationList = ['KaptureWingAction', 'Loop', 'Opening']

  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const targetRef = useRef(null);
  const targetRef2 = useRef(null);
  const modelRef = useRef(null);
  const modelRef2 = useRef(null);
  const canvasTargetRef = useRef(null);
  const targetLostTime = useRef(0);

  // set as current time
  const setTargetLostTimeNow = () => {
    targetLostTime.current = Date.now();
  };

  useEffect(() => {
    // createImageSegmenter();
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];

    const targetEl = targetRef.current;
    const targetEl2 = targetRef2.current;
    const modelEl = modelRef.current;
    const modelEl2 = modelRef2.current;

    sceneEl.addEventListener('renderstart', async () => {
      arSystem.start(); // start AR 
    });

    const setEnterAnimation = (e, el) =>{
      console.log("target found");
      console.log("modelEl",el)
      // Play if the current time - targetLostTime is greater than 1 second
      if (Date.now() - targetLostTime.current > 2000) {
        console.log('Animation opening');
        el.setAttribute('animation-mixer', {
          clip: animationList[2], // play the "Opening" animation first
          timeScale: 1,
          loop: 'once',
          clampWhenFinished: false,
        });
      }
      else {
        console.log('continue looping animation');
      }
    }

    const onAnimationLooping = (event, el) => {
      console.log("modelEl animation-looping seting next animation");
      el.setAttribute('animation-mixer', {
        clip: animationList[1], // switch to the "Loop" animation
        timeScale: 1,
        loop: 'pingpong'
      });
    }

    //  animation-mixer="clip: open wing type 1; loop: once; clampWhenFinished: false; timeScale: 0'"
    targetEl.addEventListener('targetFound', (e)=> setEnterAnimation(e, modelEl));
    targetEl2.addEventListener('targetFound', (e)=> setEnterAnimation(e, modelEl2));
    targetEl.addEventListener('animation-finished', (e)=> onAnimationLooping(e, modelEl));
    targetEl2.addEventListener('animation-finished', (e)=> onAnimationLooping(e, modelEl));
    targetEl.addEventListener('targetLost', setTargetLostTimeNow);

    targetEl.addEventListener('model-loaded', () => {
      console.log('model loaded');
      traverseModel(modelEl);
    });

    targetEl2.addEventListener('model-loaded', () => {
      console.log('model2 loaded');
      traverseModel(modelEl2);
    });

    const traverseModel = (node) => {

      const mesh = node.getObject3D('mesh');
      mesh.traverse(obj => obj.frustumCulled = false);

      // Traverse child nodes
      if (node.children && node.children.length > 0) {
        for (let i = 0; i < node.children.length; i++) {
          traverseModel(node.children[i]);
        }
      }
  };

    return () => {
      arSystem.stop();
      targetEl.removeEventListener('targetFound', setEnterAnimation);
      targetEl2.removeEventListener('targetFound', setEnterAnimation);
      targetEl.removeEventListener('animation-finished', onAnimationLooping);
    }
  }, []);

  return (
    // 九宮格
    <>
      <a-scene 
        debug 
        ref={sceneRef} 
        mindar-image="filterMinCF:0.001; filterBeta: 1000; imageTargetSrc: kapture/targets_2.mind; autoStart: false; uiLoading: yes; uiError: yes; uiScanning: no;" 
        light="defaultLightsEnabled: true"
        color-space="sRGB" 
        embedded 
        renderer="colorManagement: true, physicallyCorrectLights: true, antialias: false; logarithmicDepthBuffer: true; colorManagement: true; maxCanvasWidth: 1920; maxCanvasHeight: 1920; sortObjects: true;" 
        vr-mode-ui="enabled: true" 
        device-orientation-permission-ui="enabled: true"
      >
        {/* card */}
        {/* <a-scene debug ref={sceneRef} mindar-image="filterMinCF:0.000000001; filterBeta: 100000; imageTargetSrc: https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.0/examples/image-tracking/assets/card-example/card.mind; autoStart: false; uiLoading: yes; uiError: yes; uiScanning: yes;" color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: true" device-orientation-permission-ui="enabled: true"> */}
        {/* dickies */}
        {/* <a-scene debug ref={sceneRef} mindar-image="imageTargetSrc: wing_animated/targets.mind; autoStart: false; uiLoading: yes; uiError: yes; uiScanning: yes;" color-space="sRGB" embedded renderer="colorManagement: true, physicallyCorrectLights" vr-mode-ui="enabled: true" device-orientation-permission-ui="enabled: true"> */}
        <a-assets>
          {/* <a-asset-item id="kapture" src="kapture/Kapture_NoBlue_v1.gltf"></a-asset-item> */}
          <a-asset-item id="kapture_blue" src="kapture/Kapture_629.gltf"></a-asset-item>
          {/* <a-asset-item id="kapture_blue_new" src="kapture/Kapture_withBlue_30Alpha_times3.gltf"></a-asset-item> */}
          {/* <a-asset-item id="bearModel" src="https://cdn.jsdelivr.net/gh/hiukim/mind-ar-js@1.2.1/examples/image-tracking/assets/band-example/bear/scene.gltf"></a-asset-item> */}
        </a-assets>

        <a-camera ref={cameraRef} position="10 10 100" look-controls="enabled: false">
        </a-camera>
        <a-light type="directional" color="#FFFFFF" position="0 1 10" look-at="a-entity" castShadow="true"></a-light>
        <a-entity ref={targetRef} mindar-image-target="targetIndex: 0">
          {/* <a-gltf-model ref={modelRef} src="#kapture" scale="3 3 3" position="0 -3.8 0.5"></a-gltf-model> */}
          {/* <a-gltf-model ref={modelRef} src="#kapture_blue_new" mindar-image-target="targetIndex: 0"></a-gltf-model> */}
          <a-gltf-model ref={modelRef} src="#kapture_blue" scale="3.2 3.2 3.2" position="0 -3.8 0.5"></a-gltf-model>
          {/* <a-gltf-model ref={modelRef} rotation="-90 180 0 " position="-0.7 0.25 0" scale="0.005 0.005 0.005" src="#wing"></a-gltf-model> */}
          {/* <a-image src="#my-body">  </a-image> */}
          {/* <a-gltf-model rotation="0 0 0 " position="0 0.5 0" scale="0.05 0.05 0.05" src="#bearModel" animation-mixer="timeScale: 1"></a-gltf-model> */}
        </a-entity>

        <a-entity ref={targetRef2} mindar-image-target="targetIndex: 1" mindar-image-target-body-occluder="drawCanvas: false">
          <a-gltf-model ref={modelRef2} src="#kapture_blue" scale="3.2 3.2 3.2" position="0 -3.8 0"></a-gltf-model>
        </a-entity>
      </a-scene>
    </>

  )
}
