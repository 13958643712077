import {
  EffectComposer,
  EffectPass,
  RenderPass,
  SMAAEffect,
} from "postprocessing";

import { Color, DataTexture } from "three";
import { AlphaMaskEffect } from './alphaMaskEffect.js';
import { Controller } from './index.js';

// eslint-disable-next-line no-undef
AFRAME.registerComponent('mindar-image-target-body-occluder', {
  
  dependencies: ['mindar-image-system', 'mindar-image-target'],

  schema: {
    drawCanvas: { type: 'boolean', default: false }
  },

  controller: null,
  container: null,
  video: null,
  canvas: null,
  drawCanvas: false,
  passAdded: false,
  legendColors: [
    [255, 197, 0, 255], // Vivid Yellow
    [128, 62, 117, 0], // Strong Purple
    [255, 104, 0, 0], // Vivid Orange
    [166, 189, 215, 0], // Very Light Blue
    [193, 0, 32, 0], // Vivid Red
    [206, 162, 98, 0], // Grayish Yellow
    [129, 112, 102, 0], // Medium Gray
    [0, 125, 52, 0], // Vivid Green
    [246, 118, 142, 0], // Strong Purplish Pink
    [0, 83, 138, 0], // Strong Blue
    [255, 112, 92, 0], // Strong Yellowish Pink
    [83, 55, 112, 0], // Strong Violet
    [255, 142, 0, 0], // Vivid Orange Yellow
    [179, 40, 81, 0], // Strong Purplish Red
    [244, 200, 0, 0], // Vivid Greenish Yellow
    [127, 24, 13, 0], // Strong Reddish Brown
    [147, 170, 0, 0], // Vivid Yellowish Green
    [89, 51, 21, 0], // Deep Yellowish Brown
    [241, 58, 19, 0], // Vivid Reddish Orange
    [35, 44, 22, 0], // Dark Olive Green
    [0, 161, 194, 0] // Vivid Blue
  ],
  init: async function () {

    if (!this.el.sceneEl.renderStarted) {
      console.log('render target not loaded');
      console.log("this.el.sceneEl",this.el.sceneEl);
      this.el.sceneEl.addEventListener('loaded', ()=>{
        console.log('loaded');
        this.init.apply(this);
      });
    } else {
      const arSystem = this.el.sceneEl.systems['mindar-image-system'];
      this.container = this.el.sceneEl.parentNode;
      this.video = arSystem.video;
      this.drawCanvas = this.data.drawCanvas;
  
      console.log("init this.video",this.video)
  
      this.controller = new Controller();
      await this.controller.init();
  
      if (this.data.drawCanvas) {
        this.canvas = document.createElement('canvas');
        this.canvas.className += "a-canvas";
        // this.canvas.setAttribute('width', "100%");
        // this.canvas.setAttribute('height', "100%");
        this.canvas.style.position = 'absolute';
        this.canvas.style.top = '0px';
        this.canvas.style.left = '0px';
        this.canvas.style.zIndex = '1';
        this.container.appendChild(this.canvas);
      }
  
      // add effer composer
      this.addEffectComposer();
  
  
      // process video after target found
      this.el.addEventListener('targetFound', () => {
  
        // fit ui elements for mind ar js created things
        this._resize(this);
        this.video = this.el.sceneEl.systems['mindar-image-system'].video;
        this.controller.processVideo({ video: this.video, detectImageSegmenterCallback: (r) => { this.detectCallback.apply(this, [r] ) } })
      });
  
      this.el.addEventListener('targetLost', () => {
        console.log("this.drawCanvas", this.drawCanvas);
        this.controller.stopProcessVideo();
        if (this.data.drawCanvas) {
          const canvasEl = this.canvas
          const canvasCtx = canvasEl.getContext('2d');
          canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);
        }
        this.resetMask.apply(this);
      });
    }

  },
  resetMask: function () {
    const video = this.video;
   
    const data = new Uint8Array(4 * video.videoWidth * video.videoHeight);
    for ( let i = 0; i < data.length; i ++ ) {
      const maskVal = 255;
      data[ i ] = maskVal;
    }
    const uint8Array = new Uint8ClampedArray(data.buffer);

    const texture = new DataTexture(uint8Array, video.videoWidth, video.videoHeight);
    texture.flipY = true;
    texture.needsUpdate = true;
    this.tMask = texture;
    this.alphaMaskEffect.uniforms.get("tMask").value = texture;
  },
  detectCallback: function (result) {
    if (this.data.drawCanvas) {

      const video = this.video;
      const canvasEl = this.canvas
      const canvasCtx = canvasEl.getContext('2d');
      const legendColors = this.legendColors;

      let imageData = canvasCtx.getImageData(
        0,
        0,
        video.videoWidth,
        video.videoHeight
      ).data;
      const mask = result.categoryMask.getAsUint8Array();
      let j = 0;
      for (let i = 0; i < mask.length; ++i) {
        const maskVal = mask[i];
        const legendColor = legendColors[maskVal % legendColors.length];
        // const maskVal = mask[i];
        // const legendColor = [maskVal, maskVal, maskVal, maskVal];
        imageData[j] = (legendColor[0]) / 2;
        imageData[j + 1] = (legendColor[1]) / 2;
        imageData[j + 2] = (legendColor[2]) / 2;
        imageData[j + 3] = (legendColor[3]) / 2;
        j += 4;
      }
      const uint8Array = new Uint8ClampedArray(imageData.buffer);
      const dataNew = new ImageData(
        uint8Array,
        video.videoWidth,
        video.videoHeight
      );
      if (this.drawCanvas) {
        createImageBitmap(dataNew, { resizeWidth: canvasEl.width, resizeHeight: canvasEl.height }).then(
          (ImageBitmap) => {
            canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);
            canvasCtx.drawImage(ImageBitmap, 0, 0);
          }
        );
      }
    }

    // TODO add clip effect
    const video = this.video;
    const container = this.container;
    const mask = result.categoryMask.getAsUint8Array();

    const maskWidth = result.categoryMask.width;
    const maskHeight = result.categoryMask.height;
   
    const data = new Uint8Array(4 * video.videoWidth * video.videoHeight);
    for ( let i = 0; i < mask.length; i ++ ) {
      const maskVal = mask[i] > 100? 255: 0;
      const stride = i * 4;
      data[ stride ] = maskVal;
      data[ stride + 1 ] = maskVal;
      data[ stride + 2 ] = maskVal;
      data[ stride + 3 ] = maskVal;
    }
    const uint8Array = new Uint8ClampedArray(data.buffer);

    const vih = video.clientHeight;
    const viw = video.clientWidth;
    const videoAspectRatio = viw / vih;
    const maskAspectRatio = maskWidth / maskHeight;
    
    const scale = videoAspectRatio > maskAspectRatio ? vih / maskHeight : viw / maskWidth;
    
    const maskScaledWidth = Math.floor(maskWidth * scale);
    const maskScaledHeight = Math.floor(maskHeight * scale);
    
    const cw = container.clientWidth;
    const ch = container.clientHeight;
    const extrax = Math.floor((cw - maskScaledWidth) / 2);
    const extray = Math.floor((ch - maskScaledHeight) / 2);
    
    const output = new Uint8Array(4 * cw * ch);
    
    for (let i = 0; i < output.length; i += 4) {
      const outputY = Math.floor(i / (cw * 4));
      const outputX = (i / 4) % cw;
      const inputY = Math.floor((outputY - extray) / scale);
      const inputX = Math.floor((outputX - extrax) / scale);
      const position = (inputY * maskWidth + inputX) * 4;
      output[i] = uint8Array[position];
      output[i + 1] = uint8Array[position + 1];
      output[i + 2] = uint8Array[position + 2];
      output[i + 3] = uint8Array[position + 3];
    }    
    
    const texture = new DataTexture(output, cw, ch);
    texture.flipY = true;
    texture.needsUpdate = true;
    this.tMask = texture;
    this.alphaMaskEffect.uniforms.get("tMask").value = texture;
  },
  addEffectComposer: function () {
    const sceneEl = this.el.sceneEl;
    const scene = sceneEl.object3D;
    const renderer = sceneEl.renderer;
    const render = renderer.render;
    const camera = sceneEl.camera;

    // const video = this.video;

    const width = 640;
    const height = 380;

    const size = width * height;
    const data = new Uint8Array(4 * size);
    const color = new Color(0xff0000);

    const r = Math.floor(color.r * 255);
    const g = Math.floor(color.g * 255);
    const b = Math.floor(color.b * 255);


    console.log("rgb", r, g, b)
    for (let i = 0; i < size; i++) {
      const stride = i * 4;
      data[stride] = r;
      data[stride + 1] = g;
      data[stride + 2] = b;
      data[stride + 3] = 255;
    }

    // used the buffer to create a DataTexture
    const texture = new DataTexture(data, width, height,);
    texture.needsUpdate = true;

    this.tMask = texture;

    const alphaMaskEffect = new AlphaMaskEffect(texture);
    this.alphaMaskEffect = alphaMaskEffect;


    const smaaEffect = new SMAAEffect();


    const composer = new EffectComposer(renderer);
    this.composer = composer;
    this.originalRenderMethod = render;

    const renderPass = new RenderPass(scene, camera);
    const effectPass = new EffectPass(
      camera,
      alphaMaskEffect,
      smaaEffect,
    );
    this.effectPass = effectPass;

    composer.addPass(renderPass);
    composer.addPass(effectPass);

    // Hijack the render method.
    let calledByComposer = false;

    console.log("renderer.capabilities.isWebGL2",renderer.capabilities.isWebGL2)

    renderer.render = function () {

      if (calledByComposer) {

        render.apply(renderer, arguments);

      } else {

        calledByComposer = true;
        composer.render();
        calledByComposer = false;

      }

    };
  },

  /**
   * Clean up when the system gets removed.
   */
  remove() {
    this.controller.stopProcessVideo();

    this.composer.renderer.render = this.originalRenderMethod;
    this.composer.dispose();

  },
  // to fit mind-ar-js image-target created video size
  _resize: function(component) {
    if (this.drawCanvas) {

      const video = component.video;
      const container = component.container;

      let vw, vh; // display css width, height
      const videoRatio = video.videoWidth / video.videoHeight;
      const containerRatio = container.clientWidth / container.clientHeight;
      if (videoRatio > containerRatio) {
        vh = container.clientHeight;
        vw = vh * videoRatio;
      } else {
        vw = container.clientWidth;
        vh = vw / videoRatio;
      }

      component.canvas.style.top = (-(vh - container.clientHeight) / 2) + "px";
      component.canvas.style.left = (-(vw - container.clientWidth) / 2) + "px";
      component.canvas.style.width = vw + "px";
      component.canvas.style.height = vh + "px";
    }
  }
});