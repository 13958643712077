import { ImageSegmenter, FilesetResolver, ImageSegmenterResult } from '@mediapipe/tasks-vision';
const DEFAULT_imageSegmenterWasmSource = "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm";
const DEFAULT_imageSegmenterOption = {
  baseOptions: {
    modelAssetPath:
      "selfie_segmenter_landscape.tflite",
    delegate: "GPU"
  },
  outputCategoryMask: true,
  outputConfidenceMasks: false,
  runningMode: "VIDEO"
};

class Controller {
  constructor() {
    this.processingVideo = false;
    this.detectImageSegmenter = null;
  }

  async init() {
    console.log("createImageSegmenter init ing")
    async function createImageSegmenter(imageSegmenterWasmSource = DEFAULT_imageSegmenterWasmSource, imageSegmenterOption = DEFAULT_imageSegmenterOption) {
      const wasm = await FilesetResolver.forVisionTasks(imageSegmenterWasmSource);
      const imageSegmenter = await ImageSegmenter.createFromOptions(wasm, imageSegmenterOption);
      return imageSegmenter;
    }
    this.detectImageSegmenter = await createImageSegmenter();

    console.log("createImageSegmenter init finish")
  }

  processVideo(input) {
    if (this.processingVideo) return;
    this.processingVideo = true;

    const doProcess = async () => {
      const startTimeMs = performance.now();
      this.detectImageSegmenter.segmentForVideo(input.video, startTimeMs, input.detectImageSegmenterCallback);

      requestAnimationFrame(() => {
        if (this.processingVideo) {
          doProcess();
        }
      });
    }
    requestAnimationFrame(doProcess);
  }
  async detectImageSegmenter(video, startTimeMs, callBack) {
    this.imageSegmenter.segmentForVideo(video, startTimeMs, callBack);
  }
  stopProcessVideo() {
    this.processingVideo = false;
  }
}

export {
  Controller
}